/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiRfid } from 'react-icons/bi';
import { BsFillFuelPumpFill } from 'react-icons/bs';
import { IoTimer } from 'react-icons/io5';
import { GiFuelTank } from 'react-icons/gi';
import { MdOutlinePropaneTank } from 'react-icons/md';
import { BsFuelPump } from 'react-icons/bs';
import { Spinner } from '@chakra-ui/react';
import StartFirebase from '../../../firebase/index';
import {
  ref,
  set,
  get,
  update,
  remove,
  child,
  onValue,
} from 'firebase/database';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useEffect, useState } from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
// // import FuelIdle from '../../../assets/img/fuelempty.png';
// // import FuelFilling from '../../../assets/img/fuelfilling.png';
// import { FuelFilling } from 'components/icons/fuel';
export default function UserReports() {
  const [SpinnerLoading, setSpinnerLoading] = useState(false);

  const [HardwareData, setHardwareData] = useState({
    TotalFlow: 0,
    TagStatus: false,
    SwitchStatus: 0,
    mills: 0,
  });
  const [TriggerData, setTriggerData] = useState({
    Pump: false,
  });

  const db = StartFirebase();

  function dataFetch() {
    const dbRef = ref(db, 'fuel');
    onValue(dbRef, (snapshot) => {
      let records = [];
      // console.log(snapshot);
      snapshot.forEach((childSnapshot) => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({ key: keyName, data: data });
      });
      setHardwareData(records[0]?.data?.number);
      setTriggerData(records[1]?.data);
      console.log(records);
    });
  }
  useEffect(() => {
    dataFetch();
  }, []);

  function formatMillis(millis) {
    // Calculate total seconds
    const totalSeconds = Math.floor(millis / 1000);
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format the components to always show two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Return formatted string
    return `${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`;
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const greyColor = useColorModeValue('secondaryGray.500', 'secondaryGray.500');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={GiFuelTank} color={brandColor} />
              }
            />
          }
          name="Fuel Tank Volume"
          value={`100 L`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlinePropaneTank}
                  color={brandColor}
                />
              }
            />
          }
          name="Fuel Dispensed"
          value={`${(HardwareData?.TotalFlow / 1000).toFixed(2)} L`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlinePropaneTank}
                  color={brandColor}
                />
              }
            />
          }
          name="Fuel Left"
          value={`${100 - (HardwareData?.TotalFlow / 1000).toFixed(2)} L`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlinePropaneTank}
                  color={brandColor}
                />
              }
            />
          }
          name="RFID Status"
          value={`${HardwareData?.TagStatus ? 'Tag Detected' : 'No Tag'}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={BsFuelPump} color={brandColor} />
              }
            />
          }
          endContent={
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                // update(ref(db, 'fuel/stream/Pump'), {
                //   Pump: !TriggerData.Pump,
                // });
                setSpinnerLoading(true);
                fetch(
                  'https://hardwaretesting-60413-default-rtdb.firebaseio.com/fuel/stream/Pump.json',
                  {
                    method: 'PUT',
                    body: !TriggerData.Pump,
                  },
                )
                  .then((e) => {
                    console.log(e);
                  })
                  .finally(() => {
                    setSpinnerLoading(false);
                  });
              }}
            >
              {SpinnerLoading ? (
                <Spinner size="xl" />
              ) : (
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={BsFillFuelPumpFill}
                      color={TriggerData.Pump ? brandColor : greyColor}
                      // greyColor brandColor
                    />
                  }
                />
              )}
            </div>
          }
          name="Fuel Dispense Status"
          value={`${HardwareData?.SwitchStatus == 0 ? 'Idle' : 'Operational'}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={IoTimer} color={brandColor} />}
            />
          }
          name="Device Uptime"
          value={`${formatMillis(HardwareData?.mills)}`}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <PieCard />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent />
        <DailyTraffic />
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
function StartFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyDq4b_d17iYsNZGnFa9t-F7hLbxBCrRl-A',
    authDomain: 'hardwaretesting-60413.firebaseapp.com',
    databaseURL: 'https://hardwaretesting-60413-default-rtdb.firebaseio.com',
    projectId: 'hardwaretesting-60413',
    storageBucket: 'hardwaretesting-60413.appspot.com',
    messagingSenderId: '737347717488',
    appId: '1:737347717488:web:0c944447a4a956363627b5',
    measurementId: 'G-Q7112SG7R5',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return getDatabase(app);
}
export default StartFirebase;
